/*
 * @Descripttion:
 * @Author: yuyang
 * @Date: 2021-12-08 10:51:46
 * @LastEditors: yuyang
 * @LastEditTime: 2022-06-24 09:56:56
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

export function getEnterpriseTenantList(
  page: number,
  limit: number,
  enterpriseName: string,
  agencyCode: string,
  mobile: string
): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesApply/pageByTenant`,
    method: 'GET',
    params: {
      page,
      limit,
      enterpriseName,
      agencyCode,
      mobile,
    },
  });
}

export function getPersonalTenantList(
  page: number,
  limit: number,
  name: string,
  agencyCode: string,
  mobile: string
): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesPersonalApply/pageByTenant`,
    method: 'GET',
    params: {
      page,
      limit,
      name,
      agencyCode,
      mobile,
    },
  });
}

export function getTenantDetail(agencyCode: string): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesApply/getByAgency/${agencyCode}`,
    method: 'GET',
  });
}

export function getPersonalTenantDetail(
  agencyCode: string
): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesPersonalApply/${agencyCode}`,
    method: 'GET',
  });
}

export function getAgenciesApplication(
  currentPage: number,
  pageRecorders: number,
  agencyCode: string
): Promise<BaseResponse> {
  return request({
    url: `/license-resource/1/agenciesApplication/${agencyCode}/page`,
    method: 'GET',
    params: {
      currentPage,
      pageRecorders,
    },
  });
}

export function getSubAccountDataList(
  page: number,
  limit: number,
  agencyCode: string
): Promise<BaseResponse> {
  return request({
    url: `agency-resource/1/agenciesUserR/users`,
    method: 'GET',
    params: {
      page,
      limit,
      agencyCode,
    },
  });
}
